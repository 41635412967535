html {
  font-size: 62.5%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 14px;
  line-height: 1.428571429;
  color: #333333;
  background-color: #ffffff;
}

#app {
  padding: 2em;
}

#title {
  display: inline-block;
}

#title>span {
  font-size: 3.5em;
  vertical-align: middle;
  font-weight: 100;
  margin-left: 0.15em;
}

#title:hover {
  cursor: pointer;
}

#search {
  margin-top: 3em;
  margin-bottom: 3em;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: searchfield-cancel-button;
}

#search>.input-group {

  width: 50%;
  margin-right: auto;
  margin-left: auto;
  min-width: 20em;
}

#search-text-entry {
  font-size: 1.5em;
  padding: 2.8rem;
}

#results {
  margin-top: 2em;
  width: 110em;
}

td.searchable:hover {
  background-color: rgb(237, 237, 237);
  cursor: pointer;
}

th.header:hover {
  background-color: rgb(237, 237, 237);
  cursor: pointer;
}

tbody>tr {
  height: 5em;
}

.table td {
  vertical-align: middle;
}

.summary {
  width: 20em;
  vertical-align: top;
}

.size-icon-large,
.size-icon-medium,
.size-icon-small,
.size-icon-micro {
  display: inline-block;
  width: 30px;
  vertical-align: middle;
  text-align: center;
  margin-right: 10px;
  background-color: black;
  border-radius: 50%;
}

.size-icon-large {

  width: 2em;
  height: 2em;
}

.size-icon-medium {

  width: 1.2em;
  height: 1.2em;
}

.size-icon-small {

  width: 0.5em;
  height: 0.5em;
}

.size-icon-micro {

  width: 0.2em;
  height: 0.2em;
}

table {
  border-spacing: 0;
}
